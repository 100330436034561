import React from "react";
import { Faq } from "../containers";
import { Seo, NavCustom, Footer } from "../components/";

const faq = () => {
	return (
		<div>
			<Seo
				title='FAQs: Best Auto and Car Window Tinting in Ottawa, Canada'
				description='Clear your every doubt and see the Best Car Window Tinting in Ottawa services that make you choose them instead of other Auto Window Tinting Ottawa for exquisite work.'
				keywords={[
					"Best Car Window Tinting",
					"Auto Window Tinting Ottawa",
				]}
			/>
			<div id='root'>
				<NavCustom />
				<Faq />
				<Footer />
			</div>
		</div>
	);
};

export default faq;
